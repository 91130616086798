import { types, getRoot } from 'mobx-state-tree';
import firebase, { FieldValue } from './../helpers/firebase';
import Timestamp from './Timestamp';
const db = firebase.firestore();

export default types
  .model('PostComment', {
    id: types.string,
    postId: types.string, // <string> id of the post this comment is posted under
    text: types.string, // <string> comment text / content
    author_uid: types.string, // <string> id of the author user
    author_avatar: types.string, // <string> avatar image URL of the author user
    author_displayName: types.string, // <string> author users name
    author_signature: '', // the author signature
    author_profile_page: '', // the author profile page
    likesCount: types.number, // <int> Number of likes
    liked_by: types.array(types.string), // <array[string]> array of user ids that liked this post comment
    created: types.maybe(types.maybeNull(Timestamp)), // <timestamp> when the post comment was created
  })
  .volatile((self) => ({
    likedNow: null,
  }))
  .views((self) => ({
    get thisUser() {
      const thisUser = getRoot(self).auth.user;
      return thisUser;
    },
    get hasLiked() {
      const thisUser = self.thisUser;
      if (typeof self.likedNow === 'boolean') {
        return self.likedNow;
      } else {
        return self.liked_by.includes(thisUser.id);
      }
    },
    get ref() {
      return db.collection('post_comments').doc(self.id);
    },
  }))
  .actions((self) => ({
    updateText(text) {
      self.text = text;
      self.ref.update({
        text: text,
      });
    },
    likeToggle() {
      const thisUser = self.thisUser;
      if (self.hasLiked) {
        db.collection('post_comment_likes')
          .doc(thisUser.id + self.id)
          .delete();
      } else {
        db.collection('post_comment_likes')
          .doc(thisUser.id + self.id)
          .set({
            uid: thisUser.id,
            postId: self.postId,
            commentId: self.id,
            created: FieldValue.serverTimestamp(), // <timestamp> when the post was liked
          });
      }
      self.likedNow = !(self.likedNow === 'boolean' ? self.likedNow : self.liked_by.includes(thisUser.id));
    },
    delete() {
      self.thisUser.logModActivity('deleted-comment', {
        postId: self.postId,
        commentId: self.id,
        commentText: self.text,
        commentAuthorUID: self.author_uid,
      });
      self.ref.delete();
    },
  }));
