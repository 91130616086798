import { types, flow, getSnapshot, getParent } from 'mobx-state-tree';
import firebase, { FieldValue, getFunctionURL } from './../helpers/firebase';
import Timestamp, { MaybeTimestamp } from './Timestamp';
import ModActivityLog, { ACTIVITY_TYPES } from './ModActivityLog';
import LittleOne from './LittleOne';
import UserMemo from './UserMemo';
import UserStats from './UserStatsManager';
import UserActivityLog from './UserActivityLog';
import { snapToArray } from 'helpers/firestore';
import { cleanUndefinedProps } from 'helpers/firestore';

const db = firebase.firestore();

const UserSettings = types.model('UserSettings', {
  'share-tracker-events-consultants': types.optional(types.boolean, false),
});

const ModLastActivity = types
  .model('ModLastActivity', {
    ping: types.optional(types.maybeNull(Timestamp), null),
    time: types.optional(types.maybeNull(Timestamp), null),
    label: '',
    objectID: '',
    objectType: '',
  })
  .actions((self) => ({
    set(key, value) {
      if (typeof key === 'object') {
        for (let k of Object.keys(key)) {
          self.set(k, key[k]);
        }
      }
      self[key] = value;
    },
    unsetActivity() {
      self.time = null;
      self.label = '';
      self.objectID = '';
      self.objectType = '';
    },
  }));

const User = types
  .model('DBUser', {
    id: types.string,
    email: types.string,
    avatar: '',
    IsCommunityTermsAccepted: false,
    first_name: '',
    last_name: '',
    timezone: types.optional(types.string, ''),
    modLastActivity: types.optional(ModLastActivity, {}),
    modActiveStatus: 'offline',
    modAbsentMessage: '',
    modSignature: '',
    modProfilePage: '',
    flags: types.array(types.string),
    invitations: types.optional(
      types.model({
        total: 0,
        used: 0,
      }),
      {}
    ),
    roles: types.model({
      village_moderator_manager: false,
      village_moderator: false,
      admin: false,
      super_admin: false,
      marketing_admin: false,
    }),
    little_ones: types.array(LittleOne),
    devices: types.array(
      types.model('UserDevice', {
        id: '', // device ID
        token: '', // device token
        type: '', // device type
        appVersion: '',
        model: '',
        allowTracking: types.maybe(types.boolean),
        added: MaybeTimestamp, // timestamp when it was added
        updated: MaybeTimestamp,
        refreshTime: MaybeTimestamp, // timestamp when it was last refreshed
        ipAddress: '', // current ipAddress when the device was last added/updated/refreshed
        city: '', // current city location when the device was last added/updated/refreshed
        country: '', // current country location when the device was last added/updated/refreshed
      })
    ),
    settings: types.optional(UserSettings, {}),
    stats: types.optional(UserStats, {}),
    modActivityLog: types.optional(ModActivityLog, {}),
    userActivityLog: types.optional(UserActivityLog, {}),
    lastActivity: types.optional(MaybeTimestamp, null),
    created: Timestamp,
    disabled: false,
    relatedUsers: types.array(
      types.model('related_users', {
        id: types.string,
        type: types.string,
        time: Timestamp,
        uids: types.array(types.string),
      })
    ),
  })
  .volatile((self) => ({
    savingInvitations: false,
    subscription_logs: null,
    subscription_logs_loading: false,
    addingPaymentFID: false,
    loadingEventsSchedule: false,
    loadingTrackerEvents: false,
    eventsSchedules: null,
    trackerEvents: null,
    updating: false,
    changingPassword: false,
    changingSubscription: false,
    changingEmail: false,
    changingAccess: false,
    changingRole: false,
    deleting: false,
    memo: null,
    loadingUserMemo: false,
    owns: null,
    updatedData: false,
    relatedUsersLoaded: false,
    transferringPurchases: false,
    deletingDevice: false,
  }))
  .actions((self) => ({
    set(key, value) {
      if (key.includes('.')) {
        let obj;
        let keys = key.split('.');
        let last = keys.pop();
        obj = keys.reduce((m, key) => {
          return m[key];
        }, self);
        obj[last] = value;
      } else {
        self[key] = value;
      }
    },
    setInvitationSlots(newTotal) {
      self.invitations.total = newTotal;
    },
    addInvitations: flow(function* (num) {
      self.savingInvitations = true;
      if (isNaN(num * 1)) {
        return;
      }
      yield db
        .collection('invitations')
        .doc()
        .set({
          coupon: '',
          total: num * 1,
          used: 0,
          from: {
            uid: self.id,
            displayName: `${self.first_name} ${self.last_name}`,
          },
          claimedBy: [],
          claimedByNames: [],
          created: new Date(),
        });
      yield new Promise((resolve) => setTimeout(resolve, 5 * 1000));
      self.invitations.total += num;
      self.savingInvitations = false;
    }),
    ensureSubLogs: flow(function* () {
      if (self.subscription_logs || self.subscription_logs_loading) return;
      self.subscription_logs_loading = true;
      const manager = getParent(self, 2);
      self.subscription_logs = yield manager.getSubLogs(self.id);
      self.subscription_logs_loading = false;
    }),
    endUserOnlineSession() {
      const functionURL = getFunctionURL('httpsUserEndOnlineSession');
      const ping = self.modLastActivity.ping;
      navigator.sendBeacon(
        functionURL,
        JSON.stringify({
          uid: self.id,
          modLastActivityPingMS: ping ? ping.milliseconds : '',
          secret: 'MCiQR3ek1vfMi4EC',
        })
      );
    },
    logModActivity: async function (type, meta) {
      const uid = self.id;
      if (!ACTIVITY_TYPES.includes(type)) {
        console.error('[mod-activity-log] Invalid type:', type);
        return;
      }
      if (!uid) {
        console.error('[mod-activity-log] Missing UID, for log of type:', type);
        return;
      }
      try {
        await db
          .collection('mod_activity_log')
          .doc()
          .set({
            type: type, // )
            meta: Object.assign(
              {
                // "postId": "", // (optional) relevant post id
                // "commentId": "", // (optional) relevant comment id
                // "oldModStatus": "", // (optional) old/previous modStatus of a post
                // "newModStatus": "", // (optional) new/current modStatus of a post
                // "addedModAssignedTo": "", // (optional) added mod uid
                // "removedModAssignedTo": "", // (optional) removed mod uid
              },
              meta || {}
            ),
            time: FieldValue.serverTimestamp(), // time when this happened
            uid: uid, // uid of consultant
          });
      } catch (err) {
        console.error('error writing log', err);
      }
    },

    updateData(data) {
      for (let key of Object.keys(data)) {
        if (self[key] !== 'undefined') {
          self[key] = data[key];
        }
      }
      self.updatedData = true;
    },
    setOnlineActivity: flow(function* (label, objectID, objectType) {
      if (self.modActiveStatus === 'absent') return;
      if (label) {
        self.modActiveStatus = 'online';
        self.modLastActivity.set({
          time: new Date(),
          ping: new Date(),
          label,
          objectID,
          objectType,
        });
      } else {
        self.modActiveStatus = 'online';
        self.modLastActivity.set({
          ping: new Date(),
        });
      }
      const data = getSnapshot(self);
      yield db.collection('users').doc(self.id).update({
        modActiveStatus: data.modActiveStatus,
        modLastActivity: data.modLastActivity,
      });
    }),
    unsetOnlineActivity() {
      self.modLastActivity.unsetActivity();
      const data = getSnapshot(self);
      db.collection('users').doc(self.id).update({
        modActiveStatus: data.modActiveStatus,
        modLastActivity: data.modLastActivity,
      });
    },
    toggleFlag: flow(function* (flag) {
      const mode = self.flags.includes(flag) ? 'remove' : 'add';

      try {
        const flags = self.flags.slice(0);
        if (mode === 'remove') {
          flags.splice(flags.indexOf(flag), 1);
        } else {
          flags.push(flag);
        }
        self.flags = flags;
        const setFlags = firebase.functions().httpsCallable('httpsUserSetFlags');
        yield setFlags({ uid: self.id, flags: flags });
      } catch (error) {
        const flags = self.flags.slice(0);
        if (mode === 'remove') {
          flags.push(flag);
        } else {
          flags.splice(flags.indexOf(flag), 1);
        }
        self.flags = flags;
        self.changingFlags = false;
        return Promise.reject(error);
      }
    }),
    pingOnline() {
      if (self.modActiveStatus === 'absent') return null;
      self.setOnlineActivity();
    },
    loadUserOwns: flow(function* () {
      const result = yield db.collection('user_owns').doc(self.id).get();
      self.owns = result.data();
    }),
    addPaymentFID: flow(function* (type, fid) {
      try {
        // self.addingPaymentFID = true;
        const addPaymentFID = firebase.functions().httpsCallable('httpsUserAddPaymentFid');
        yield addPaymentFID({ uid: self.id, type, fid });
        // self.addingPaymentFID = false;
      } catch (error) {
        // self.addingPaymentFID = false;
        return Promise.reject(error);
      }
    }),
    deletePaymentFID: flow(function* (type, fid) {
      try {
        const deletePaymentFID = firebase.functions().httpsCallable('httpsUserDeletePaymentFid');
        yield deletePaymentFID({ uid: self.id, type, fid });
      } catch (error) {
        return Promise.reject(error);
      }
    }),
    loadMemo: flow(function* () {
      self.loadingUserMemo = true;
      const result = yield db.collection('user_notes').doc(self.id).get();
      const data = result.data();
      if (!data) {
        // no memo yet
        self.memo = UserMemo.create({
          uid: self.id,
        });
      } else {
        self.memo = UserMemo.create(data);
      }
      self.loadingUserMemo = false;
    }),
    loadEventsSchedule: flow(function* () {
      self.loadingEventsSchedule = true;
      const getEventSchedule = firebase.functions().httpsCallable('httpsEventSchedule');
      const date = new Date();
      const month = date.getMonth() + 1;
      const day = date.getDate();
      const dateString = `${date.getFullYear()}-${month > 9 ? month : '0' + month}-${day > 9 ? day : '0' + day}`;
      const result = yield getEventSchedule({ otherUserID: self.id, startDate: dateString });
      self.eventsSchedules = result.data.schedules;
      self.loadingEventsSchedule = false;
      console.log(result, self.eventsSchedules, self.loadingEventsSchedule);
    }),
    loadTrackerEvents: flow(function* (loID) {
      self.loadingTrackerEvents = true;
      const result = yield db
        .collection('users')
        .doc(self.id)
        .collection('tracker_events')
        .where('littleOneID', '==', loID)
        .orderBy('startTime', 'desc')
        .limit(100)
        .get();
      self.trackerEvents = result.docs.map((doc) => {
        return Object.assign({}, doc.data(), { id: doc.id });
      });
      self.loadingTrackerEvents = false;
    }),
    setDisabled: flow(function* (disabled) {
      self.changingAccess = true;
      const httpsUserSetDisabled = firebase.functions().httpsCallable('httpsUserSetDisabled');
      yield httpsUserSetDisabled({ uid: self.id, disabled });
      self.disabled = disabled;
      self.changingAccess = false;
    }),
    setAdminRole: flow(function* (admin) {
      self.changingRole = true;
      const httpsUserSetUserRole = firebase.functions().httpsCallable('httpsUserSetUserRole');
      yield httpsUserSetUserRole({ uid: self.id, role: 'admin', enabled: admin });
      self.roles.admin = admin;
      self.changingRole = false;
    }),
    setVillageModeratorRole: flow(function* (moderator) {
      self.changingRole = true;
      const httpsUserSetUserRole = firebase.functions().httpsCallable('httpsUserSetUserRole');
      yield httpsUserSetUserRole({ uid: self.id, role: 'village_moderator', enabled: moderator });
      self.roles.village_moderator = moderator;
      self.changingRole = false;
    }),
    setVillageModeratorManagerRole: flow(function* (moderatorManager) {
      self.changingRole = true;
      const httpsUserSetUserRole = firebase.functions().httpsCallable('httpsUserSetUserRole');
      yield httpsUserSetUserRole({ uid: self.id, role: 'village_moderator', enabled: moderatorManager });
      yield httpsUserSetUserRole({ uid: self.id, role: 'village_moderator_manager', enabled: moderatorManager });
      self.roles.village_moderator = moderatorManager;
      self.roles.village_moderator_manager = moderatorManager;
      self.changingRole = false;
    }),
    setMarketingAdminRole: flow(function* (marketingAdmin) {
      self.changingRole = true;
      const httpsUserSetUserRole = firebase.functions().httpsCallable('httpsUserSetUserRole');
      yield httpsUserSetUserRole({ uid: self.id, role: 'marketing_admin', enabled: marketingAdmin });
      self.roles.marketing_admin = marketingAdmin;
      self.changingRole = false;
    }),
    changePassword: flow(function* (newPassword) {
      self.changingPassword = true;
      const changePassword = firebase.functions().httpsCallable('httpsUserChangePassword');
      yield changePassword({ otherUserID: self.id, newPassword });
      self.changingPassword = false;
    }),
    updateFirstLastName: flow(function* (firstName, lastName) {
      self.updating = true;
      self.first_name = firstName;
      self.last_name = lastName;
      const httpsUserUpdateDetails = firebase.functions().httpsCallable('httpsUserUpdateDetails');
      yield httpsUserUpdateDetails({
        first_name: firstName,
        last_name: lastName,
      });
      self.updating = false;
    }),
    updateModSettings: flow(function* ({ modActiveStatus, modAbsentMessage, timezone, modSignature, modProfilePage }) {
      try {
        self.updating = true;
        yield db.collection('users').doc(self.id).update({
          timezone: timezone,
          modActiveStatus: modActiveStatus,
          modAbsentMessage: modAbsentMessage,
          modSignature: modSignature,
          modProfilePage: modProfilePage,
        });
        self.modActiveStatus = modActiveStatus;
        self.modAbsentMessage = modAbsentMessage;
        self.timezone = timezone;
        self.modSignature = modSignature;
        self.modProfilePage = modProfilePage;
        self.updating = false;
      } catch (error) {
        self.updating = false;
        return Promise.reject(error);
      }
    }),
    changeEmail: flow(function* (newEmail) {
      self.changingEmail = true;
      const changeEmail = firebase.functions().httpsCallable('httpsUserChangeEmail');
      try {
        yield changeEmail({ uid: self.id, newEmail });
      } catch (err) {
        self.changingEmail = false;
        throw err;
      }
      self.email = newEmail;
      self.changingEmail = false;
    }),
    addSubscription: flow(function* (subID) {
      self.changingSubscription = true;
      const httpsUserSetSubscriptionPackage = firebase.functions().httpsCallable('httpsUserSetSubscriptionPackage');
      yield httpsUserSetSubscriptionPackage({
        uid: self.id,
        mode: 'add',
        subscriptionPackageID: subID,
      });
      self.changingSubscription = false;
    }),
    removeSubscription: flow(function* (subID) {
      self.changingSubscription = true;
      const httpsUserSetSubscriptionPackage = firebase.functions().httpsCallable('httpsUserSetSubscriptionPackage');
      yield httpsUserSetSubscriptionPackage({
        uid: self.id,
        mode: 'remove',
        subscriptionPackageID: subID,
      });
      self.changingSubscription = false;
    }),
    delete: flow(function* (email) {
      self.deleting = true;

      try {
        if (email !== self.email) {
          throw Error("User's email not match");
        }

        const deleteUser = firebase.functions().httpsCallable('httpsUserDelete');
        const response = yield deleteUser({ uid: self.id });
        if (!response.data.success) throw Error("Error deleting user's account");

        self.deleting = false;
        return Promise.resolve(response.data.success);
      } catch (error) {
        self.deleting = false;

        return Promise.reject(error);
      }
    }),
    signUp: flow(function* ({ first_name, last_name, email, password }) {
      try {
        const httpsPublicSignUp = firebase.functions().httpsCallable('httpsPublicSignUp');
        const result = yield httpsPublicSignUp({
          first_name,
          last_name,
          email,
          password,
        });

        return Promise.resolve(result);
      } catch (error) {
        return Promise.reject(error);
      }
    }),
    loadRelatedUsers: flow(function* () {
      if (self.relatedUsersLoaded) return;

      try {
        const doc = db.collection('related_users').where('uids', 'array-contains-any', [self.id]);
        const result = yield doc.get();

        if (result.empty) {
          self.relatedUsersLoaded = true;
          return;
        }

        self.relatedUsers = snapToArray(result);

        const uids = self.relatedUsers.map((relatedUser) => relatedUser.uids).flat();
        yield getParent(self, 2).loadByIds(uids);

        self.relatedUsersLoaded = true;
      } catch (error) {
        console.error(error);
      }
    }),
    transferPurchases: flow(function* (destination_email) {
      try {
        self.transferringPurchases = true;
        const httpsUserTransferPurchase = firebase.functions().httpsCallable('httpsUserTransferPurchase');
        const result = yield httpsUserTransferPurchase({
          source_email: self.email,
          destination_email,
        });
        self.transferringPurchases = false;

        return Promise.resolve(result);
      } catch (error) {
        self.transferringPurchases = false;
        return Promise.reject(error);
      }
    }),
    deleteDevice: flow(function* (deviceID) {
      try {
        self.deletingDevice = true;

        const deviceToBeRemoved = self.devices.find((device) => device.id === deviceID);

        if (!deviceToBeRemoved) {
          throw new Error('Device not found');
        }

        const devices = self.devices
          .filter((device) => device !== deviceToBeRemoved.id)
          .map(
            (device) => cleanUndefinedProps(device) // clean up undefined properties
          );
        yield db.collection('users').doc(self.id).update({
          devices,
        });
        self.devices.splice(self.devices.indexOf(deviceToBeRemoved), 1);
        self.deletingDevice = false;
      } catch (error) {
        self.deletingDevice = false;
        return Promise.reject(error);
      }
    }),
  }))
  .views((self) => ({
    get isSuperAdmin() {
      return self.roles.super_admin;
    },
    get isAdmin() {
      return self.roles.admin;
    },
    get isModerator() {
      return self.roles.village_moderator;
    },
    get isModeratorManager() {
      return self.roles.village_moderator_manager;
    },
    get isVillageModerator() {
      return self.roles.village_moderator_manager;
    },
    get isMarketingAdmin() {
      return self.roles.marketing_admin;
    },
    get modName() {
      return self.fullName.replace(/little ?ones ?-?/i, '').trim();
    },
    get fullName() {
      return self.first_name + ' ' + self.last_name;
    },
    get role() {
      if (self.roles.admin) {
        return 'admin';
      } else if (self.roles.village_moderator) {
        return 'village_moderator';
      } else {
        return '';
      }
    },
    get roleLabel() {
      if (self.roles.marketing_admin) {
        return 'Marketing Admin';
      }

      return self.roles.admin ? 'Administrator' : 'Moderator';
    },
    hasAnyRole(roles) {
      for (let role of roles) {
        if (self.roles[role]) {
          return true;
        }
      }
      return false;
    },
  }));

export default User;
